<template>
  <div>
    <v-list
      id="cartItemList"
      flat
      subheader
      class="cart-item-list"
      :class="{ 'checkout-item-list': isCheckout }"
    >
      <template>
        <v-subheader
          class="text-body-1 d-flex justify-space-between default--text"
        >
          <CartItemCount :count="cart.totalItems" />
          <v-switch
            v-model="showSectors"
            :label="$t('miniCart.splitProductsOnCategory')"
          ></v-switch>
        </v-subheader>
      </template>

      <div
        v-if="!showSectors"
        class="no-sectors"
        :class="[
          { 'cart-item-list-no-checkout': !isCheckout },
          { 'cart-item-list-no-checkout-higher': !isCheckout }
        ]"
      >
        <div
          v-for="cartItem in cart.cartItems"
          :key="cartItem.cartItemId"
          class="align-center px-md-2"
        >
          <CartItem :item="cartItem" :isCheckout="isCheckout" />
        </div>
      </div>
      <v-expansion-panels
        v-else
        v-model="panel"
        accordion
        flat
        multiple
        tile
        :class="[
          { 'cart-item-list-no-checkout': !isCheckout },
          { 'cart-item-list-no-checkout-higher': !isCheckout }
        ]"
      >
        <v-expansion-panel
          v-for="([categoryId, group], index) in itemGroups"
          v-bind:key="categoryId"
        >
          <v-expansion-panel-header
            v-on:click.prevent="() => {}"
            class="text-body-2 grey lighten-2 px-3"
          >
            <div class="d-flex align-center">
              <span class="font-weight-bold mr-2">{{ group.name }}</span>
              <span>
                {{ `${$tc("lists.products", group.products.length)}` }}
              </span>
            </div>
            <template v-slot:actions>
              <v-icon x-small>
                {{ panel.includes(index) ? "$minus" : "$plus" }}
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <transition-group name="bounce">
              <CartItem
                class="sectorItem"
                v-for="item in group.products"
                v-bind:key="item.itemId"
                :item="item"
                :isCheckout="isCheckout"
              />
            </transition-group>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-list>
  </div>
</template>
<style lang="scss">
/* we will explain what these classes do next! */
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.cart-item-list {
  .v-expansion-panel {
    .v-expansion-panel-header {
      min-height: 20px;
      padding: 10px 24px;
    }
    .v-expansion-panel-content .v-expansion-panel-content__wrap {
      padding: 0px !important;
      .v-list-item {
        min-height: 70px;
      }
    }
  }
  .checkout-item-list {
    .no-sectors {
      .v-list-item {
        min-height: 70px !important;
        padding: 8px 0px;
      }
    }
  }
  .v-subheader {
    height: auto;
    min-height: #{$list-subheader-min-height};
    background: var(--v-grey-lighten1);
  }
  .cart-item-list-no-checkout {
    min-height: 30vh;
    height: calc(
      100vh - (#{$cart-info-height} + #{$list-subheader-min-height}) - 80px
    );
    //padding-top: #{$list-subheader-min-height};
    //margin-top: -#{$list-subheader-min-height};
    padding-bottom: #{$summary-height};
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--v-grey-darken2);
      border-radius: 20px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .cart-item-list-no-checkout-higher {
    height: calc(
      100vh -
        (
          #{$cart-info-height} + #{$list-subheader-min-height} + #{$cartlist-diff}
        )
    ) !important;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .cart-item-list-no-checkout {
      min-height: auto;
      max-height: 100%;
    }
  }
}
</style>

<script>
import CartItem from "./CartItem.vue";
import CartItemCount from "./CartItemCount.vue";
import baskoCustomService from "@/service/baskoCustomService";
import AnalyticsService from "~/service/analyticsService";
import { mapActions, mapState } from "vuex";
export default {
  name: "CartItemList",
  components: { CartItem, CartItemCount },
  props: {
    isCheckout: { type: Boolean, default: false },
    showSectors: { type: Boolean, default: false }
  },
  data() {
    return {
      panel: []
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    acceptAlternatives: {
      get() {
        let value = true;
        this.cart.cartItems.forEach(cartItem => {
          if (cartItem.cartItemInfo.accept_alternatives != "true") {
            value = false;
          }
          return value;
        });
        return value;
      },
      set(value) {
        this.setCartItemInfo({
          items: this.cart.cartItems,
          name: "accept_alternatives",
          value: "" + value
        });
      }
    },
    orderComment: {
      get() {
        return this.cart.cartInfos.order_comment;
      },
      set(value) {
        console.log(value);
        //update on blur
      }
    },
    itemGroups() {
      let groups = new Map();
      if (this.cart.cartItems) {
        this.cart.cartItems.forEach(item => {
          let category = this.$store.getters["category/lookupCategory"](
            item.product.categoryId
          );
          if (category === undefined || category === null) {
            category = {
              categoryId: -1,
              name: "Senza categoria"
            };
          }
          if (groups.has(category.categoryId)) {
            groups.get(category.categoryId).products.push(item);
          } else {
            groups.set(category.categoryId, {
              name: category.name,
              products: [item]
            });
          }
        });
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.panel = Array.from(Array(groups.size).keys());
      return groups;
    }
  },
  methods: {
    handleRemove() {},
    changeTimeslot() {},
    changeDelivery() {},
    ...mapActions({
      setCartItemInfo: "cart/setCartItemInfo",
      setCartInfo: "cart/setCartInfo"
    }),
    async deleteSubscriptionPlan(subscriptionPlanId) {
      let params = {
        subscriptionPlanId: subscriptionPlanId
      };
      let result = await baskoCustomService.deleteSubscriptionPlan(params);
      if (
        (result.response && result.response.status == 0) ||
        result.status == 0
      ) {
        global.EventBus.$emit(
          "deleteSubscriptionPlan",
          result.data.subscriptionPlans
        );
      }
    }
  },
  created() {
    AnalyticsService.viewProducts(this.cart.cartItems, this.itemListName, 0);
  }
};
</script>
